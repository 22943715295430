<template>
  <div class="model">
    <div v-if="isModel" class="noneModel">
      <div class="icon"></div>
      <div class="text">暂无3D模型</div>
      <div class="text">请点击右上方“上传模型文件”按钮上传</div>
    </div>
    <ThreeLoader :backgroundColor="backgroundColor" :modelColor="modelColor" :MapData="MapData" v-else class="ModelRander" />
  </div>
</template>
<script>
import ThreeLoader from './threeLoader.vue';
export default {
  props: ['MapData', 'backgroundColor', 'modelColor'],
  components: {
    ThreeLoader
  },
  data() {
    return {
      isModel: false
    };
  },
  watch: {
    async MapData() {
      const res = await this.$apis.Map3d.isOpen();
      this.isModel = !res;
    },
    deep: true
  }
};
</script>
<style lang="scss" scoped>
.model {
  height: 685px;
  width: 100%;
  .noneModel,
  .ModelRander {
    width: 100%;
    height: 100%;
  }
  .noneModel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .icon {
      width: 146px;
      height: 146px;
      background: url('../assets/model.png') no-repeat center center;
      background-size: contain;
    }
    .text {
      font-family: stand;
      font-size: 18px;
      color: #a8b0b2;
    }
  }
}
</style>
