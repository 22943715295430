<template>
  <div id="container"></div>
</template>
<script>
import * as THREE from 'three';
import { MTLLoader } from '../common/MTLLoader.js';
import { OBJLoader } from '../common/OBJLoader.js';
import { OrbitControls } from '../common/OrbitControls.js';
export default {
  props: ['MapData', 'backgroundColor', 'modelColor'],
  data() {
    return {
      controls: null,
      container: null,
      scene: null,
      renderer: null,
      loader: null,
      bg: '',
      model: ''
    };
  },

  mounted() {
    if (this.MapData && this.MapData.modelAddress) {
      this.init();
    }
  },
  watch: {
    MapData() {
      let three = document.querySelector('#container > canvas');
      if (three) {
        three.remove(1);
      }
      this.init();
    },
    modelColor() {
      let three = document.querySelector('#container > canvas');
      three.remove(1);
      this.init();
    },
    backgroundColor() {
      let three = document.querySelector('#container > canvas');
      three.remove(1);
      this.init();
    },
    deep: true
  },
  methods: {
    async init() {
      // 地址
      let materAddress = '';
      let newStr;
      if (this.MapData.materAddress) {
        materAddress = process.env.VUE_APP_API_BASE_URL + this.MapData.materAddress;
        // 路径;
        let items = materAddress.split('model.mtl');
        newStr = items.join('');
      }
      this.container = document.querySelector('#container');
      this.camera = new THREE.PerspectiveCamera(45, 1920 / 685, 0.1, 2000);
      this.camera.position.z = 40;
      this.camera.position.y = 20;
      this.scene = new THREE.Scene();
      // 设置背景色
      const ambientLight = new THREE.AmbientLight(0xcccccc, 0.4);
      this.scene.background = new THREE.Color(this.backgroundColor);
      this.scene.add(ambientLight);

      const pointLight = new THREE.PointLight(this.modelColor, 1);
      pointLight.position.set(600, 400, 2);
      this.camera.add(pointLight);
      this.scene.add(this.camera);
      new MTLLoader().setPath(newStr).load('model.mtl', materials => {
        materials.preload();

        new OBJLoader()
          .setMaterials(materials)
          .setPath(newStr)
          .load('model.obj', object => {
            this.loader = object;
            this.scene.add(object);
          });
      });
      this.renderer = new THREE.WebGLRenderer();
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setSize(1920, 685);
      this.renderer.outputEncoding = THREE.sRGBEncoding;
      this.container.appendChild(this.renderer.domElement);
      // 轨道控制器
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.update();
      // 控制器配置
      this.controls.enablePan = true;
      window.addEventListener('resize', this.resize);
      this.animate();
    },
    resize() {
      this.camera.aspect = 1920 / 685;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(1920, 685);
    },
    animate() {
      this.controls.update();
      this.renderer.render(this.scene, this.camera);
      requestAnimationFrame(this.animate);
    }
  }
};
</script>
<style lang="scss" scoped>
#container {
  canvas {
    width: 1920px;
    height: 685px !important;
  }
}
</style>
